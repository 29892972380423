<template>
	<div class="test">
		<div class="page_1">
			<div class="top-nav-class">
			</div>
			<PageNav :show_num="4" />
			<h1>{{ new_detail.headline }}</h1>
			<h2>{{ new_detail.publishTime }}</h2>
			<div class="page_1_center">
				<div v-html="new_detail.content" ></div>
			</div>
		</div>

		<PageBot :show_num="4" />
		<el-backtop class="back_up" target="" :bottom="100" :visibility-height="620" :right="10">
			<svg class="icon icon_xiangshang" aria-hidden="true">
				<use xlink:href="#icon-xiangshang"></use>
			</svg>
		</el-backtop>
	</div>
</template>

<script>
	import pdf from 'vue-pdf'
	import ProdSpec from "@/components/ProdSpec.vue";
	import PageNav from "@/components/PageNav.vue"
	import PageBot from "@/components/PageBottom.vue"
	import Swiper from "swiper";
	import "swiper/css/swiper.min.css";
	import {
		resetRem
	} from '@/all_js/all';
	import {
		getNewsDetail
	} from '@/api/index';
	import {
		clickEffect
	} from "@/all_js/click";
	export default {
		name: 'KS8223',
		components: {
			PageBot,
			PageNav,
			ProdSpec,
			pdf
		},
		created() {
			this.go_news_detail(this.$route.query.id)
		},
		watch: {

		},

		mounted() {

		},
		data() {
			return {
				new_detail: {},
				avtive_nav: "",
				screenWidth: null,
				list_status: true,
				avtive_img: 0
			}
		},
		methods: {
			// 新闻详情
			go_news_detail(id){
				getNewsDetail({
					id:id
				}).then(res=>{
					this.new_detail = res.data
					this.new_detail.publishTime = this.new_detail.publishTime.split(" ")[0]
				})
			},
		}
	}
</script>
<style scoped lang="less">
	* {
		margin: 0;
		padding: 0;
		// transition: all 1s; // 设置动画效果
	}

	li {
		list-style-type: none;
	}

	.page_1 {
		width: 100%;
		// height: 100vh;
		.top-nav-class{
			width:100%;
			height:84px;
			background-color: #2981de;
		}
		.page_1_center{
			width: 80%;
			margin: 0 auto;
		}
	}

	h1{
		width: 100%;
		text-align: center;
		margin: 30px 0 30px 0;
		color: #2981de;
	}
	h2{
		width:80%;
		margin: 0 auto;
		margin-bottom: 10px;
		text-align: right;
		color: #666666;
	}
	




	.back_up {
		height: 40px;
		width: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 30px;
		background-color: #49b1f5;

		.icon_xiangshang {
			height: 30px;
			width: 30px;
			border-radius: 30px;
		}
	}

	.back_up:hover {
		background-color: #49b1f5;
	}
</style>
<style scoped lang="less">
	// @media only screen and (min-width:1371px) {}
</style>